import React from 'react';
import '../../App.css';

export default function UnderConstruction() {
  return (
  <div className='construction'>
  <img className='construction-img' src='/images/under_construction.jpg' alt="construction" width={400}/>
  <h1 className='construction-text'>Coming Soon</h1>
  </div>
  );
}
