export const MenuItems = [
    {
      title: 'Seeds',
      path: '/coming-soon',
      cName: 'dropdown-link'
    },
    {
      title: 'Salsas',
      path: '/coming-soon',
      cName: 'dropdown-link'
    },
    {
      title: 'Spices',
      path: '/coming-soon',
      cName: 'dropdown-link'
    }
  ];