import React, {useRef, useEffect} from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function AutoPlaySilentVideo(props) {
  const videoRef = useRef(undefined);
  useEffect(() => {
      videoRef.current.defaultMuted = true;
  })
  return (
      <video
          className={props.className}
          ref={videoRef}
          loop
          autoPlay
          muted
          playsInline>
          <source src={props.video} type="video/mp4"/>
      </video>
  );
}

function HeroSection() {
  return (
    <div className='hero-container'>
      <AutoPlaySilentVideo
      className='pepper-video'
      video='/videos/jalepeno.mp4'
      />
      <h1>Can you handle the heat...</h1>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          GET STARTED
        </Button>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={console.log('hey')}
        >
          How Its Made <i className='far fa-play-circle' />
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
