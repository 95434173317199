import React from 'react';
import Navbar from './components/navbar/Navbar'
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UnderConstruction from './components/pages/Construction';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
        <Route path='/' exact element={ <Home />}></Route>
        <Route path='/coming-soon' exact element={ <UnderConstruction />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
